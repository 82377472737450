<template>
  <div style="margin: 10px;">
    <b-row>
      <b-col sm="12" lg="1"></b-col>
      <b-col sm="12" lg="8">
        <work-subject :p_showDetails="{'type': 'selected', 'subject_name': 'treatmentschemadrugs'}"></work-subject>
        <hr>
        <h3>Tedavi şemasına ait ilaçları düzenleme Komponenti</h3>
        <div style="width: 100%;">
          <img src="../../../public/demo/treatmentschemadrugs.png" width="100%">
        </div>
        <hr>
        <template v-for="(code_example, code_example_index)  in d_componentExample.schema_drugs">
          <h3> {{ code_example.name }} </h3>
          <div class="code-text">{{ code_example.code }}</div>
        </template>
        <hr>
        <sql-database :p_tableShowDetails="{'type': 'selected', 'table_name': 'TreatmentSchemaSystemDrugs'}"></sql-database>
        <hr>
        <sql-database :p_tableShowDetails="{'type': 'selected', 'table_name': 'TreatmentSchemaUserDrugs'}"></sql-database>
        <hr>
      </b-col>
      <b-col sm="12" lg="3"></b-col>
    </b-row>
  </div>
</template>

<script>
import {
  default as WorkSubject
} from '@/components/widgets/WorkSubject';
import {
  default as SqlDatabase
} from '@/components/widgets/SqlDatabase';
import { mapGetters } from 'vuex';
export default {
  name: 'TreatmentShemaDrugs',
  computed: mapGetters({
    // lang: 'lang'
  }),
  components: {
    WorkSubject,
    SqlDatabase,
  },
  props: {},
  data () {
    return {
      patient_data: {
        'sex': '0', // 0 erkek, 1 kadın
        'age': 55,
        'color': 1,
        'last_laboratory': {
          'creatinine': {
            'date': '2022-01-01T09:00:00.000000',
            'val': 1.2,
            'gfr': 70
          }
        },
        'last_bsa_data': {
          'date': '2022-01-01T09:00:00.000000',
          'bmi': 22,
          'ideal_weight': 60,
          'adjusted_weight': 70,
          'weight': 75,
          'bsa': 1.70,
          'length': 174
        }
      },
      d_schemaData: {
        'drugs': [{
          "period": 21,
          "frequency_first": 1,
          "frequency_second": 1,
          "fluid_value": 100,
          "duration": 10,
          "unit": {
            "value": "mg",
            "label": "mg"
          },
          "drug_note": "",
          "delay": 0,
          "recipe_data": {},
          "type": "before", // before, after, during, drug, mix
          "relation_index": 2,
          "drug_order": 1,
          "fluid": {
            "value": "1",
            "label": "100 cc %0.9 sodium chloride"
          },
          "day": [1, 8],
          "repeat": 3,
          "drug": {
            "value": "1",
            "label": "Deksametazon"
          },
          "duration_wait": 0,
          "dosage": 8,
          "dosage_form": {
            "value": "12",
            "label": "İntravenöz"
          }
        }, {
          "period": 21,
          "frequency_first": 1,
          "frequency_second": 1,
          "fluid_value": 0,
          "duration": 10,
          "unit": {
            "value": "mg",
            "label": "mg"
          },
          "drug_note": "",
          "delay": 0,
          "recipe_data": {},
          "type": "mix", // before, after, during, drug, mix
          "relation_index": 0,
          "drug_order": 1,
          "fluid": "",
          "day": [1, 8],
          "repeat": 3,
          "drug": {
            "value": "3",
            "label": "Granisetron"
          },
          "duration_wait": 0,
          "dosage": 1,
          "dosage_form": {
            "value": "12",
            "label": "İntravenöz"
          }
        }, {
          "period": 21,
          "frequency_first": 1,
          "frequency_second": 1,
          "fluid_value": 100,
          "duration": 10,
          "unit": {
            "value": "mg",
            "label": "mg"
          },
          "drug_note": "",
          "delay": 0,
          "recipe_data": {},
          "type": "drug", // before, after, during, drug, mix
          "relation_index": "",
          "drug_order": 1,
          "fluid": {
            "value": "1",
            "label": "100 cc %0.9 sodium chloride"
          },
          "day": [1, 8],
          "repeat": 3,
          "drug": {
            "value": "2",
            "label": "Paklitaksel"
          },
          "duration_wait": 0,
          "dosage": 8,
          "dosage_form": {
            "value": "12",
            "label": "İntravenöz"
          }
        }],
        'name': 'dosetaksel (100 mg/m2 , 1.gün)  [21 gündebir , 3kür]',
        'reference': 'Sequential adjuvant epirubicin-based and docetaxel chemotherapy for node-positive breast cancer patients: the FNCLCC PACS 01 Trial.'
      },
      d_createTableSqlExample: 'CREATE TABLE PatientBsa (PatientId int, weight_kg float, weight_lbs float, ideal_weight float, adjusted_weight float, height_inch float, height_cm float, bmi float, bsa float,bsa_date datetime );',
      d_vyaSqlExample: 'SELECT * FROM PatientBsa WHERE PatientId = 12345',
      d_componentExample: {
        'schema_drugs': [
          { 'code': '<schema-drugs :p_schemaData="d_schemaData" :p_patientData="patient_data"></schema-drugs>', 'name': 'Örnek komponent kullanımı' },
        ]
      },
    };
  },
  created: function () {},
  beforeMount () {},
  mounted: function () {},
  methods: {},
  watch: {}
}

</script>

<style type="text/css">


</style>

